<script>
	import Base from '@backend/Base.vue';
	import BOGen from '@helper/BOGen';

	export default {
		name: "BoUserLevel",
		extends: Base,
		data() {
			return {
				Name: "BoUserLevel",
				mrMenuRole: {},
				mrModuleRole: {},
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
		},
		watch: {
			'$route.query'(v) {
				this.refreshData()
			}
		}
	};
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>

		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="panel panel-flat" v-if="!$route.params.id">
			<div class="panel-body panel-filter">
				<VForm @resp="search">
					<div class="row">
						<div class="col-sm-3">
							<h3 class="box-title">{{ObjectName}} List <span
									v-if="data.total">({{(data.total||0).format()}})</span></h3>
						</div>
						<div class="col-sm-5">
						</div>
						<div class="col-sm-3">
							<div class="has-feedback has-feedback-left">
								<input type="search" v-model="filter.search" class="form-control"
									placeholder="Type and hit Enter">
								<div class="form-control-feedback">
									<i class="icon-search4 text-size-base text-muted"></i>
								</div>
							</div>
						</div>
						<div class="col-sm-1">
							<router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
						</div>
					</div>
				</VForm>
			</div>
			<div class="table-responsive ajax-table">
				<table class="table table-striped table-bordered">
					<thead>
						<tr>
							<th>#</th>
							<th>
								<SortField name="bul_level_name"></SortField>
							</th>
							<th>Status</th>
							<th width="120px">Action</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(v,k) in data.data" :key="k">
							<td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
							<td>{{v.bul_level_name}}</td>
							<td>
								<StatusLabel :status="v.status"></StatusLabel>
							</td>
							<td class="btn-action">
								<router-link class="icon_action" :to="{name:Name,params:{id:v.id}}" v-tooltip="'Edit'">
									<i class="icon-pencil"></i></router-link>
								<a href="javascript:;" class="icon_action" @click="changeStatus(k)"
									v-tooltip="'Change Status'"><i class="icon-settings"></i></a>
								<a href="javascript:;" class="icon_action" @click="deleteItem($event,k)"
									v-tooltip="'Remove'"><i class="icon-trash"></i></a>
							</td>
						</tr>
						<tr v-if="NotFound">
							<td colspan="99">
								<h3 class="tc">{{NotFound}}</h3>
							</td>
						</tr>
						<tr v-if="data.data===false">
							<td colspan="99">
								<LoadingSpinner light></LoadingSpinner>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="panel-body">
				<div class="pull-right">
					<Pagination :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
				</div>
			</div>
		</div>

		<!-- ======================================================================== 
			FORM CRUD
		============================================================================= -->
		<div class="panel panel-flat" v-if="$route.params.id">
			<VForm @resp="submitForm" method="post">
				<div class="panel-heading">
					<h2 class="panel-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h2>
				</div>
				<div class="panel-body">
					<div class="info"></div>
					<div class="row">
						<div class="col-sm-6">
							<BoField name="bul_level_name" v-model="row.bul_level_name"></BoField>
							<BoField name="bul_menu_rel">
								<select2 name="bul_menu_rel" v-bind="validation('bul_menu_rel')" :options="mrMenuRole"
									v-model="row.bul_menu_rel" class="form-control" multiple></select2>
							</BoField>
						</div>

						<div class="col-sm-6">
							<!-- <BoField name="bul_module_rel">
								<select2 name="bul_module_rel" v-bind="validation('bul_module_rel')"
									:options="mrModuleRole" v-model="row.bul_module_rel" class="form-control" multiple>
								</select2>
							</BoField> -->
							<BoField name="bul_status">
								<div class="radio-group">
									<radio name="bul_status" v-model="row.bul_status" option="Y"
										:attr="validation('bul_status')">Active</radio>
									<radio name="bul_status" v-model="row.bul_status" option="N">Inactive</radio>
								</div>
							</BoField>
						</div>

						<div class="col-xs-12">
							<div class="text-right">
								<button type="submit" class="fcbtn btn btn-info btn-outline btn-1e btn-loading">Submit
									form <i class="icon-arrow-right14 position-right"></i></button>
							</div>
						</div>
					</div>
				</div>
			</VForm>
		</div>
	</div>
</template>